function clickclear(e, t) {
    if (e.value == t) {
        e.value = ""
    }
}
function clickrecall(e, t) {
    if (e.value == "") {
        e.value = t
    }
}
function clearText(e) {
    if (e.defaultValue == e.value)e.value = ""; else if (e.value == "")e.value = e.defaultValue
}
function validEmail(e) {
    reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4}|museum|travel)$/;
    return reg.test(e)
}
function Popup(URL) {
    day = new Date;
    id = day.getTime();
    eval("page" + id + " = window.open(URL, '" + id + "', 'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=988,height=880,titlebar=no');")
}

$(function () {
    $("#seo-links").hide();
    $("img.rollover").hover(function () {
        this.src = this.src.replace(".png", "_hover.png")
    }, function () {
        this.src = this.src.replace("_hover.png", ".png")
    })

    $("#datepicker").datepicker({
        showOn: "both",
        buttonImage: "https://www.bookyourtheorytestonline.co.uk/images/calender.png",
        buttonImageOnly: true,
        minDate: "+1D",
        maxDate: "+6M",
        dateFormat: "dd/mm/yy",
        beforeShowDay: function(date) {
            var day = date.getDay();
            return [(day != 0), ''];
        }
    })

    //Postcode Lookup - getaddress.io
    $(".find-address").click(function(e) {
        e.preventDefault();
        var postcode = $(this)
            .prev()
            .val();
        var btn = $(this);
        var api_key = $(this).data("getaddresskey");

        $(btn).append(
            '<img class="loading" src="/images/loading.gif">'
        );

        $.getJSON(
            "https://api.getaddress.io/find/" +
            postcode +
            "?api-key="+api_key+"&expand=true&sort=true",
            function(data) {
                if ($("#addresses-found").length) {
                    $('#addresses-found').select2('destroy');
                    $('#addresses-found').remove();
                }
                if (data) {
                    var html = '<select id="addresses-found" style="width:100%;margin:10px 0"></select>';

                    if($('.found-addresses').length === 0) {
                        $(html).insertAfter(btn);
                        var defaultOption = '<option value="" class="default-option" disabled selected>Choose your exact address</option>';
                        $("#found-addresses").append(defaultOption);
                        $.each(data.addresses, function(key, val) {
                            var country = val.country;

                            var option =
                                '<option data-line-1="' +
                                val.line_1 +
                                '" data-line-2="' +
                                val.line_2 +
                                '" data-line-3="' +
                                val.line_3 +
                                '" data-city="' +
                                val.town_or_city +
                                '" data-county="' +
                                val.county +
                                '" data-country="' +
                                country +
                                '" data-postcode="' +
                                postcode +
                                '">';
                            if (val.line_1 != "") {
                                option += val.line_1 + ", ";
                            }
                            if (val.line_2 != "") {
                                option += val.line_2 + ", ";
                            }
                            if (val.line_3 != "") {
                                option += val.line_3 + ", ";
                            }
                            if (val.town_or_city != "") {
                                option += val.town_or_city + ", ";
                            }
                            if (val.county != "") {
                                option += val.county + ", ";
                            }
                            if (val.country != "") {
                                option += val.country + ", ";
                            }
                            option += postcode;
                            option += "</option>";

                            $("#addresses-found").append(option);
                        });
                    } else {
                        $('.found-addresses .book-form__section-select').append(html);
                        var defaultOption = '<option value="" class="default-option" disabled selected>Choose your exact address</option>';
                        $("#addresses-found").append(defaultOption);
                        $.each(data.addresses, function(key, val) {
                            var country = val.country;

                            var option =
                                '<option data-line_1="' +
                                val.line_1 +
                                '" data-line_2="' +
                                val.line_2 +
                                '" data-line_3="' +
                                val.line_3 +
                                '" data-city="' +
                                val.town_or_city +
                                '" data-county="' +
                                val.county +
                                '" data-country="' +
                                country +
                                '" data-postcode="' +
                                postcode +
                                '">';
                            if (val.line_1 != "") {
                                option += val.line_1 + ", ";
                            }
                            if (val.line_2 != "") {
                                option += val.line_2 + ", ";
                            }
                            if (val.line_3 != "") {
                                option += val.line_3 + ", ";
                            }
                            if (val.town_or_city != "") {
                                option += val.town_or_city + ", ";
                            }
                            if (val.county != "") {
                                option += val.county + ", ";
                            }
                            if (val.country != "") {
                                option += val.country + ", ";
                            }
                            option += postcode;
                            option += "</option>";

                            $("#addresses-found").append(option);
                        });

                        $('.found-addresses').removeAttr('style');
                    }

                    $(".loading").remove();

                    $(document).find("#addresses-found").select2({
                        width: '100%',
                    });
                }
            }
        ).fail(function(jqXHR, textStatus, errorThrown) {
            $(".loading").remove();
            switch (errorThrown) {
                case "Not Found":
                    alert("No addresses could be found for this postcode");
                    break;
                case "Bad Request":
                    alert("Sorry, we are having problems locating addresses at the moment, please enter your address manually.");
                    break;
                default:
                    alert("No addresses could be found for this postcode");
            }
        });
    });

    $(document).on("select2:select", "#addresses-found", function(e) {
        var data = e.params.data;
        var address_lines = data.element.dataset;
        console.log(address_lines);

        $("#addressline_1").val(address_lines.line_1);//line_1
        $("#addressline_2").val(address_lines.line_2);//line_2
        $("#addressline_3").val(address_lines.line_3);//line_3
        $("#address_town").val(address_lines.city);//city
        $("#address_county").val(address_lines.county);//county
        $("#address_country").val(address_lines.country);//country
    });

    $("a#find-nearest").click(function () {
        var e = $("#postcode-find").prop("value");
        if (e == "") {
            alert("Please ensure you have entered your postcode.");
            return false
        } else {
            $.ajax({
                type: "POST", url: "/includes/findcentres.php", timeout: 7e3, data: "postcode=" + e, success: function (e) {

                    var response = JSON.parse(e);

                    if(response.locations) {
                        for (var i = 1; i <= 2; i++) {
                            $('#test_centre_' + i ).empty();
                            $('#test-centre' + i ).empty();

                            for(var j=0; j < response.locations[i].length; j++) {
                                $('#test_centre_' + i ).append(`
                                    <option value="${response.locations[i][j]}">${response.locations[i][j]}</option>
                                `);
                                $('#test-centre' + i ).append(`
                                    <option value="${response.locations[i][j]}">${response.locations[i][j]}</option>
                                `);
                            }
                        }
                    }


                    // var t = document.getElementById("centres_wrap");
                    // t.innerHTML = e
                }
            })
        }
        return false
    });
    $("form#contact").submit(function () {
        var e = $("#name").prop("value");
        var t = $("#phone").prop("value");
        var n = $("#email").prop("value");
        var r = $("#enquiry").prop("value");
        var i = $("#booknumber").prop("value");
        if (e == "" || t == "" || n == "" || r == "" || validEmail(n) == false) {
            alert("Please ensure all fields are filled in correctly and that you have entered a valid E-Mail address.");
            return false
        } else {
            $.ajax({
                type: "POST",
                url: "https://www.bookyourtheorytestonline.co.uk/includes/submit-contact-form.php",
                timeout: 7e3,
                data: "name=" + e + "&phone=" + t + "&email=" + n + "&enquiry=" + r + "&booknumber=" + i,
                success: function () {
                    $("#success").fadeIn("fast");
                    setTimeout("window.location = 'https://www.bookyourtheorytestonline.co.uk/?page=thank_you_contact_us'", 4e3)
                }
            })
        }
        return false
    });
    $("form#callback").submit(function () {
        var e = $("#name").prop("value");
        var t = $("#phone").prop("value");
        var n = $("#subject").prop("value");
        var r = $("#callback_times").prop("value");
        if (e == "" || t == "" || n == "" || r == "") {
            alert("Please ensure all fields are filled in correctly.");
            return false
        } else {
            $.ajax({
                type: "POST",
                url: "https://www.bookyourtheorytestonline.co.uk/includes/submit-callback-form.php",
                timeout: 7e3,
                data: "name=" + e + "&phone=" + t + "&subject=" + n + "&callback_times=" + r,
                success: function () {
                    setTimeout("window.location = 'https://www.bookyourtheorytestonline.co.uk/?page=thank_you_callback'", 2e3)
                }
            })
        }
        return false
    })
    if ($('#homeSlider').length) {
        $('#homeSlider').slick({
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000
        });
    }

    $('select').select2({width:'100%'});
    $(".accordion-content").not($(this).next()).slideUp('fast');
    $('.accordion-toggle').click(function () {
        //Expand or collapse this panel
        $(this).next('.accordion-content').slideToggle('fast');

        //Hide the other panels
        $(".accordion-content").not($(this).next()).slideUp('fast');
    });

    $('.open-license').on('click', function (e) {
        e.preventDefault();

        $('.book-form__modal').addClass('book-form__modal--active');
    });

    $('.close-license').on('click', function (e) {
        e.preventDefault();

        $('.book-form__modal').removeClass('book-form__modal--active');
    });

    $('.book-form__modal').on('click', function (e) {
        e.preventDefault();

        if ($(e.currentTarget).hasClass('book-form__modal')) {
            $('.book-form__modal').removeClass('book-form__modal--active');
        }
    });

    $('input, select').on('keyup change', function (e) {
        $(this).removeClass('error');

        if($(this).next('.select2').length > 0) {
            $(this).next('.select2').removeClass('error');
        }
    });
});
